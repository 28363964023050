import {
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material'
import AllInboxIcon from '@mui/icons-material/AllInbox'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useMixPanel } from '../../../contexts/mixpanel'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { TtoggleDrawerOnMobile } from '../Sidebar'
import { MenuCalendar } from './calendar'
import { MenuCommunications } from './campaign'
import { MenuDeletedItems } from './deletedItems'
import { MenuTemplates } from './template'
import { useStyles } from '../Sidebar.styles'
import { permissionValidation } from '@novafuturltd/shared'
import { MenuLandingPages } from '../../../modules/campaign-mgmt/landing-pages/sidebar/landingPages'
import { MenuSMSProviderSwitch } from '../../../modules/campaign-mgmt/sms-provider-switch/sidebar/sms-provider-switch'
import { FeatureToggle } from '../../../contexts/toggle-feature'
import { useOAuth } from '@novafuturltd/core'
import { bet9jaProdId, bet9jaStgId } from '../../../config'

interface Props {
  permissions: string[]
  openDrawer: boolean
  hoverDrawer: boolean
  toggleDrawerOnMobile: TtoggleDrawerOnMobile
  toggleOpen: (open: boolean) => void
}

const CampaignSubMenu: FC<Props> = ({
  permissions,
  openDrawer,
  hoverDrawer,
  toggleDrawerOnMobile,
}) => {
  const intl = useIntl()
  const classes = useStyles({ openDrawer, hoverDrawer })
  const mxp = useMixPanel()
  const { pathname } = useRouter()
  const { organizationId: organisationId } = useOAuth()
  const [open, setOpen] = useState(pathname.includes('campaign-mgmt/'))
  const handleClick = () => {
    setOpen(!open)
    mxp.track('Sidebar Menu Item (Campaign Manager)', {
      purpose: 'track clicks of menu elements',
    })
  }

  return permissionValidation(permissions, 'campaign_management') ? (
    <MenuList aria-labelledby="nested-list-subheader" className={classes.root}>
      <MenuItem
        key="campaign-mgmt"
        data-test="campaign_sidebar_submenu"
        onClick={handleClick}
        className={classes.menuItem}
        selected={
          !openDrawer && !hoverDrawer && pathname.startsWith('/campaign-mgmt')
        }
      >
        <ListItemIcon data-test="campaign_mngt_sidemenu_icon">
          <AllInboxIcon />
        </ListItemIcon>
        <ListItemText
          primary={intl.formatMessage({ id: 'sidebar.campaign-mgmt' })}
          hidden={!openDrawer && !hoverDrawer}
        />
        {open ? (
          <ExpandLess
            onClick={() => setOpen(false)}
            className={classes.expandIcon}
          />
        ) : (
          <ExpandMore
            onClick={() => setOpen(true)}
            className={classes.expandIcon}
          />
        )}
      </MenuItem>
      <Collapse in={open} timeout="auto" hidden={!openDrawer && !hoverDrawer}>
        <MenuList disablePadding={true}>
          {permissionValidation(
            permissions,
            'campaign_management.communications',
          ) && (
            <MenuCommunications
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
          {permissionValidation(
            permissions,
            'campaign_management.communications',
          ) && (
            <MenuCalendar
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
          {permissionValidation(
            permissions,
            'notification_hub.template_management',
          ) && (
            <MenuTemplates
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
          <FeatureToggle.On name="epic:PDS-2785">
            {permissionValidation(
              permissions,
              'landing_pages_service.pages.view',
            ) && (
              <MenuLandingPages
                pathname={pathname}
                nested={
                  openDrawer || hoverDrawer
                    ? classes.nested
                    : classes.nestedClosed
                }
                toggleDrawerOnMobile={toggleDrawerOnMobile}
              />
            )}
          </FeatureToggle.On>
          {((organisationId === bet9jaProdId || organisationId === bet9jaStgId) 
          &&
          permissionValidation(
            permissions,
            'campaign_management.sms_provider_switch_service.pages.view',
          )
        ) && (
            <MenuSMSProviderSwitch
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
          {permissionValidation(
            permissions,
            'notification_hub.template_management',
          ) && (
            <MenuDeletedItems
              pathname={pathname}
              nested={
                openDrawer || hoverDrawer
                  ? classes.nested
                  : classes.nestedClosed
              }
              toggleDrawerOnMobile={toggleDrawerOnMobile}
            />
          )}
        </MenuList>
      </Collapse>
    </MenuList>
  ) : null
}

export default CampaignSubMenu
